import * as React from 'react';
import { cn } from '@lib/utils';
import { MaskedInput } from 'antd-mask-input';
import type { InputRef, InputProps as AntdInputProps } from 'antd';
import { Input as AntdInput } from 'antd';
import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';

export interface InputProps extends AntdInputProps {
  mask?: string;
  validation?: (value: string) => string | undefined;
}

const Input = React.forwardRef<InputRef, InputProps>(({ className, type, validation, ...props }, ref) => {
  const [error, setError] = React.useState<string | undefined>();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (validation) {
        setError(validation(value));
      }
      props.onChange?.(e);
    },
    [validation]
  );

  const inputClassName = cn(
    'flex h-10 w-full rounded-md border px-3 py-2 text-[15px] ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
    error ? 'border-red-500' : 'border-input bg-background',
    className
  );

  return (
    <div>
      {props.mask ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <MaskedInput mask={props.mask} {...props} type={type} className={inputClassName} onChange={handleChange} />
      ) : (
        <AntdInput {...props} type={type} className={inputClassName} ref={ref} onChange={handleChange} />
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
});

Input.displayName = 'Input';

export { Input };
