import React from 'react';

export const Groups = React.lazy(() =>
  import('./groups/GroupsPage').then((module) => ({ default: module.GroupsPage }))
);
export const EditGroups = React.lazy(() =>
  import('./groups/edit/EditGroupsPage').then((module) => ({ default: module.EditGroupsPage }))
);
export const CreateGroups = React.lazy(() =>
  import('./groups/create/CreateGroupsPage').then((module) => ({ default: module.CreateGroupsPage }))
);

export const Users = React.lazy(() => import('./users/UsersPage').then((module) => ({ default: module.UsersPage })));
export const Analytics = React.lazy(() =>
  import('./analytics/AnalyticsPage').then((module) => ({ default: module.AnalyticsPage }))
);
export const ActionLog = React.lazy(() =>
  import('./action-list/ActionListPage').then((module) => ({ default: module.ActionListPage }))
);
export const SearchFilter = React.lazy(() =>
  import('./search-filter/SearchFilterPage').then((module) => ({ default: module.SearchFilterPage }))
);
export const Support = React.lazy(() =>
  import('./support/Support').then((module) => ({ default: module.SupportPage }))
);

export const Profile = React.lazy(() =>
  import('./profile/ProfilePage').then((module) => ({ default: module.ProfilePage }))
);
