import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLogin } from '@lib/api/auth';
import { useCurrentUser } from '@lib/api/groups/queries/useCurrentUser';
import { Button } from '@ui/button';
import { Input } from '@ui/input';
import { Label } from '@ui/label';
import { useNavigate } from 'react-router-dom';
import { executeMutation, handleMutationError } from '@lib/api/utils';
import { EAppRoute } from '../../app/routes/constants';

export const Login = () => {
  const { mutateAsync: onLogin, error: loginError } = useLogin();
  const { isLoading: isLoadingUser, isSuccess: isUserSuccess } = useCurrentUser();
  const navigate = useNavigate();
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isUserSuccess && isLoadingUser) {
      navigate(EAppRoute.Groups);
    }
  }, [isLoadingUser, navigate, isUserSuccess]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      await executeMutation(onLogin, { login, password }, '', `${loginError || 'Ошибка сервера. Попробуйте позже.'}`);
      navigate(EAppRoute.Groups);
    } catch (err) {
      handleMutationError('Ошибка аутентификации. Попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <Label>Логин</Label>
            <Input disabled={isLoading} value={login} onChange={(e) => setLogin(e.target.value)} required />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label>Пароль</Label>
            </div>
            <Input
              type="password"
              disabled={isLoading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="grid gap-2">
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? 'Загрузка...' : 'Войти'}
            </Button>
          </div>
        </div>
      </form>
      <Toaster position="bottom-left" />
    </>
  );
};
