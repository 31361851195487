import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './setup';
import '@femida1/gui_web_core/styles.css';
import '@femida1/uikit/styles.css';
import './styles/globals.css';
import './styles/antd.css';
import { App } from '@processes/App/App';
import { ConfigProvider } from 'antd';
import { QueryProvider } from '@context/query-provider';
import { StrictMode } from 'react';
import { ErrorBoundary } from './layouts/ErrorBoundary/ErrorBoundary';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryProvider>
        <ConfigProvider theme={{ hashed: false }}>
          <App />
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      </QueryProvider>
    </ErrorBoundary>
  </StrictMode>
);
