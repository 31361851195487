export enum EAppRoute {
  Root = '/',
  Login = '/login',

  Dashboard = '/dashboard',
  Groups = '/dashboard/groups',
  GroupsCreate = '/dashboard/groups/create',
  GroupsEdit = '/dashboard/groups/edit/:group_id',
  Users = '/dashboard/users',
  Analytics = '/dashboard/analytics',
  ActionLog = '/dashboard/action-log',
  Search = '/dashboard/search-filter',
  Profile = '/dashboard/profile',
  Support = '/dashboard/support',
}
