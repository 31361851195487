import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@lib/axios';
import type { LogoutRequest } from '../types';
import { logout } from '../queries';

export const useLogout = () =>
  useMutation({
    mutationKey: ['logout'],
    mutationFn: (data: LogoutRequest) => logout(data),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: ['currentUser'] }),
  });
